import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';

export default function NotFoundPage() {
  const { imgNotFound } = useStaticQuery(graphql`
    {
      imgNotFound: file(relativePath: { eq: "404/nichtgefunden.jpg" }) {
        id
        relativePath
        childImageSharp {
          gatsbyImageData(height: 500, layout: FULL_WIDTH)
        }
      }
    }
  `);
  return (
    <Layout>
      <Helmet>
        <title>Seite nicht gefunden</title>
      </Helmet>

      <h1>
        <span role="img" aria-label="Schulterzucken">
          🤷‍️
        </span>
        &ensp;Ups, da ist was schief gelaufen...
      </h1>
      <p>Die angegebene Seite wurde leider nicht gefunden.</p>
      <ImgContainer>
        <GatsbyImage
          image={imgNotFound.childImageSharp.gatsbyImageData}
          alt="Einfach unverzichtbar! Ihre Apotheke vor Ort."
        />
      </ImgContainer>
    </Layout>
  );
}

const ImgContainer = styled.div`
  width: 100%;
  max-height: 500px;
`;
